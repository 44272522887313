<!--
 * @Description: 预定设置
 * @Author: 琢磨先生
 * @Date: 2022-06-23 01:21:19
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 11:04:55
-->

<template>
  <el-card class="box ">
    <div style="margin-bottom: 20px">预定相关设置</div>
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="防疫提示" prop="tip_covid_19_precaution">
        <el-col :span="20">
          <span class="content" v-if="!visible">{{ form.tip_covid_19_precaution }}</span>
          <el-input
            v-model="form.tip_covid_19_precaution"  type="textarea" :rows="5"
            v-if="visible"
          ></el-input>
        </el-col>
        <div class="help-block" v-if="visible">防疫提醒说明</div>
      </el-form-item>
      <el-form-item label="入住提示" prop="tip_check_in_prompt">
        <el-col :span="20">
          <span class="content"  v-if="!visible">{{ form.tip_check_in_prompt }}</span>
          <el-input
            v-model="form.tip_check_in_prompt"
            type="textarea" :rows="5"
            v-if="visible"
          ></el-input
        ></el-col>
        <div class="help-block" v-if="visible">入住提示说明</div>
      </el-form-item>

      <el-form-item label="营业执照" prop="pms_license_url">
        <el-col :span="10">
          <el-image
            :src="form.pms_license_url"
            style="width: 120px; height: 90px"
            fit="cover"
          ></el-image>
          <el-input v-model="form.pms_license_url" v-if="visible"></el-input
        ></el-col>
        <div class="help-block" v-if="visible">
          1积分可抵扣金额数，要求0~1，且小数不能超过3位，例如：0.01
          即1积分抵扣0.01元
        </div>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary" link
          @click="visible = true"
          size="small"
          v-if="!visible"
          >修改</el-button
        >
        <el-button
          type="primary"
          @click="onSubmit"
          v-if="visible"
          :loading="saving"
          >确定</el-button
        >
        <el-button @click="visible = false" v-if="visible" :disabled="saving"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      visible: false,
      saving: false,
      form: {},
      settings: {},
      rules: {
        tip_covid_19_precaution: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        tip_check_in_prompt: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        pms_license_url: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      common_api.get_settings().then((res) => {
        if (res.code == 0) {
          this.settings = res.data;
          this.form = Object.assign({}, this.settings);
        }
      });
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/setting/save/reserve", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
    .content{
        white-space: pre-line;
        /* color: var(--text-label-color); */
    }
     
</style>